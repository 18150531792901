import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    { attrs: { id: "dashboard", fluid: "", tag: "section" } },
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                VContainer,
                {
                  staticClass: "white",
                  attrs: { tag: "section", rounded: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ml-4" },
                    [
                      _c(
                        VRow,
                        [
                          _vm.totalAllocated
                            ? _c(
                                "h4",
                                {
                                  staticClass: "h4 div-info-title",
                                  staticStyle: { color: "var(--primary)" },
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.format_to_currency(
                                          _vm.totalAllocated
                                        )
                                      ) +
                                      " "
                                  ),
                                ]
                              )
                            : _c(VProgressCircular, {
                                attrs: {
                                  indeterminate: "",
                                  size: "30",
                                  color: "primary",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(VRow, [
                        _c("h5", { staticClass: "h5 div-info-title" }, [
                          _vm._v(_vm._s(_vm.$t("total_allocated"))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            VCol,
            { attrs: { cols: "12", md: "6" } },
            [
              _c(
                VContainer,
                {
                  staticClass: "white",
                  attrs: { tag: "section", rounded: "" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "ml-4" },
                    [
                      _c(
                        VRow,
                        [
                          !_vm.accessInfoLoading
                            ? _c(
                                "h4",
                                {
                                  staticClass: "h4 div-info-title",
                                  staticStyle: { color: "var(--primary)" },
                                },
                                [_vm._v(" " + _vm._s(_vm.totalUserCount) + " ")]
                              )
                            : _c(VProgressCircular, {
                                attrs: {
                                  indeterminate: "",
                                  size: "30",
                                  color: "primary",
                                },
                              }),
                        ],
                        1
                      ),
                      _c(VRow, [
                        _c("h5", { staticClass: "h5 div-info-title" }, [
                          _vm._v(_vm._s(_vm.$tc("user", 2))),
                        ]),
                      ]),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            VCol,
            {
              staticStyle: { "padding-top": "0" },
              attrs: { cols: "12", md: "4" },
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "white",
                  attrs: { tag: "section", rounded: "" },
                },
                [
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          VRow,
                          [
                            !_vm.lastDayInfoLoading
                              ? _c(
                                  "h4",
                                  {
                                    staticClass: "h4 div-info-title",
                                    staticStyle: { color: "var(--primary)" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.lastDayAccessCount) + " "
                                    ),
                                  ]
                                )
                              : _c(VProgressCircular, {
                                  attrs: {
                                    indeterminate: "",
                                    size: "30",
                                    color: "primary",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(VRow, [
                          _c("h5", { staticClass: "h5 div-info-title" }, [
                            _vm._v(_vm._s(_vm.$t("last_day_accesses"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            VCol,
            {
              staticStyle: { "padding-top": "0" },
              attrs: { cols: "12", md: "4" },
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "white",
                  attrs: { tag: "section", rounded: "" },
                },
                [
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          VRow,
                          [
                            !_vm.lastTenDaysInfoLoading
                              ? _c(
                                  "h4",
                                  {
                                    staticClass: "h4 div-info-title",
                                    staticStyle: { color: "var(--primary)" },
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(_vm.lastTenDaysAccessCount) +
                                        " "
                                    ),
                                  ]
                                )
                              : _c(VProgressCircular, {
                                  attrs: {
                                    indeterminate: "",
                                    size: "30",
                                    color: "primary",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(VRow, [
                          _c("h5", { staticClass: "h5 div-info-title" }, [
                            _vm._v(_vm._s(_vm.$t("ten_days_accesses"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _c(
            VCol,
            {
              staticStyle: { "padding-top": "0" },
              attrs: { cols: "12", md: "4" },
            },
            [
              _c(
                VContainer,
                {
                  staticClass: "white",
                  attrs: { tag: "section", rounded: "" },
                },
                [
                  _c("div", { staticClass: "ml-4" }, [
                    _c(
                      "div",
                      [
                        _c(
                          VRow,
                          [
                            !_vm.totalAcessesInfoLoading
                              ? _c(
                                  "h4",
                                  {
                                    staticClass: "h4 div-info-title",
                                    staticStyle: { color: "var(--primary)" },
                                  },
                                  [
                                    _vm._v(
                                      " " + _vm._s(_vm.totalAccessCount) + " "
                                    ),
                                  ]
                                )
                              : _c(VProgressCircular, {
                                  attrs: {
                                    indeterminate: "",
                                    size: "30",
                                    color: "primary",
                                  },
                                }),
                          ],
                          1
                        ),
                        _c(VRow, [
                          _c("h5", { staticClass: "h5 div-info-title" }, [
                            _vm._v(_vm._s(_vm.$t("accesses"))),
                          ]),
                        ]),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm.investments_chart.datasets.length > 0
        ? _c(
            VContainer,
            {
              staticClass: "white rounded",
              attrs: { tag: "section", fluid: "" },
            },
            [
              _c("h4", { staticClass: "h4 mb-2" }, [
                _vm._v(_vm._s(_vm.$tc("chart", 2))),
              ]),
              _vm.chartLoading
                ? _c(
                    VRow,
                    { attrs: { justify: "center" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "30",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    VRow,
                    [
                      _c(
                        VCol,
                        { attrs: { cols: "12", md: "12" } },
                        [
                          _c("line-chart", {
                            attrs: {
                              chartData: _vm.investments_chart,
                              options: _vm.options,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
      _vm.partners.length > 0 || _vm.partnersLoading
        ? _c(
            VContainer,
            {
              staticClass: "white mt-4 rounded",
              attrs: { tag: "section", fluid: "" },
            },
            [
              _c(VRow, [
                _c("h4", { staticClass: "h5 mt-3" }, [
                  _vm._v(_vm._s(_vm.$tc("partner", 2))),
                ]),
              ]),
              _vm.partnersLoading
                ? _c(
                    VRow,
                    { attrs: { justify: "center" } },
                    [
                      _c(VProgressCircular, {
                        attrs: {
                          indeterminate: "",
                          size: "30",
                          color: "primary",
                        },
                      }),
                    ],
                    1
                  )
                : _c(VSimpleTable, [
                    _c("thead", [
                      _c("tr", [
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("name"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("email"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$tc("user", 2))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("allocations_pending"))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$tc("investment", 2))),
                        ]),
                        _c("th", { staticClass: "text-center" }, [
                          _vm._v(_vm._s(_vm.$t("total_allocated"))),
                        ]),
                      ]),
                    ]),
                    _c(
                      "tbody",
                      _vm._l(_vm.partners, function (partner, index) {
                        return _c("tr", { key: index }, [
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(_vm.show_name(partner))),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(_vm._s(partner.Email)),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  partner.TotalUsers != 0
                                    ? partner.TotalUsers
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  partner.TotalInvested != 0
                                    ? _vm.format_to_currency(
                                        partner.TotalInvested
                                      )
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  partner.TotalInvestments != 0
                                    ? partner.TotalInvestments
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                          _c("td", { staticClass: "text-center" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  partner.TotalAllocated != 0
                                    ? _vm.format_to_currency(
                                        partner.TotalAllocated
                                      )
                                    : "-"
                                ) +
                                " "
                            ),
                          ]),
                        ])
                      }),
                      0
                    ),
                  ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }